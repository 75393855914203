<template>
    <div class="nav-wrapper footer">
        <div class="container">
            <p class="inter-medium-fuscous-gray-m">SimonKr Stock Content Production</p>
        </div>
        <div class="nav-bar container">
            <div>
                <a href="https://www.facebook.com/simonkrvideoproduction/" target="_blank">
                    <img class="icon" :src="this.$baseUrl + '/icons-dark/icon-facebook-dark.png'">
                </a>
                <a href="https://twitter.com/simonkrzic/" target="_blank">
                    <img class="icon" :src="this.$baseUrl + '/icons-dark/icon-twitter-dark.png'">
                </a>
                <a href="https://vimeo.com/simonkr" target="_blank">
                    <img class="icon" :src="this.$baseUrl + '/icons-dark/icon-vimeo-dark.png'">
                </a>
                <a href="https://www.youtube.com/user/simonkrzic" target="_blank">
                    <img class="icon" :src="this.$baseUrl + '/icons-dark/icon-youtube-dark.png'">
                </a>
                <a href="https://www.instagram.com/simonkrvp/" target="_blank">
                    <img class="icon" :src="this.$baseUrl + '/icons-dark/icon-instagram-dark.png'">
                </a>
                <a href="https://www.tiktok.com/@simonkrvideoproduction?lang=en" target="_blank">
                    <img class="icon" :src="this.$baseUrl + '/icons-dark/icon-tiktok-dark.png'">
                </a>
            </div>
            <div>
                <a href="https://www.gettyimages.com/search/photographer?photographer=simonkr" target="_blank">
                    <img class="icon-medium" :src="this.$baseUrl + '/icons-dark/getty-logo-dark.png'">
                </a>
                <a href="https://www.istockphoto.com/portfolio/simonkr?mediatype=photography" target="_blank">
                    <img class="icon-small" :src="this.$baseUrl + '/icons-dark/istock-logo-dark.png'">
                </a>
            </div>
        </div>
    </div>
</template>
    
<script>
export default {
    name: "footerElement"
};
</script>
    
<style>
.footer {
    position: relative;
    background-color: white;
    width: 100%;
    padding-top: 15px;
    z-index: 500;
}
</style>