<template>
  <div class="card_post">
    <!-- <div class="title_box"> -->
      <!-- <p id="content" class="inter-normal-fuscous-gray-m">{{ content }}</p> -->
    <!-- </div> -->
    <img v-if="type == 'image'" class="image" :src="link" />
    <video v-else class="image" autoplay muted loop id="myVideo">
      <source :src="link" type="video/mp4">
    </video>
    
    
    <p id="title" class="inter-medium-fuscous-gray-m" v-html="title"></p>
  </div>
</template>
  
<script>
export default {
  name: "postCard",
  props: ['link', 'title', 'content', 'type']
};
</script>
  
<style>
.card_post {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  cursor: pointer;
  min-width: 330px;
}

.card_post:hover>* {
  color: var(--logo-red);
  transition: 300ms;
}

/* .title_box {
  position: absolute;
  height: 35px;
  padding: 0 10px;

  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.404);
} */

.image {
  width: 100%;
  min-width: 315px;
  min-height: 260px;
  height: 100%;
  object-fit: cover;
}

#title {
  margin: 3px 0;
}

#content {
  margin: 10px 0;
}

.image::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 10px;
  bottom: 0;
  left: 0;
  background-color: #931F18;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.image:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
</style>