<template>
  <Transition appear name="fade">
    <div class="wrapper-portfolio" id="pc">
      <div class="card container" v-if="this.imgs[0]">
        <p class="heading inter-medium-alto-xl">Top projects</p>

        <div class="latest_project">
          <Transition appear name="fade">
            <flickity v-if="imgs" ref="flickity"  @init="onInit()" :options="flickityOptions">
              <div v-for="(post, index) in imgs.slice(0, 6)" :class="'carousel-cell' + ' cell' + index" :key="post.title" >
                <a :href="post.acf.getty__istock_link" target="_blank">
                  <img class="img11"  v-if="post.acf.image_1 != ''" @mouseover="setSelected(index)" :src="post.acf.image_1" />
                  <video v-else class="img11" @mouseover="setSelected(index)" autoplay muted loop id="myVideo">
                    <source :src="post.acf.video" type="video/mp4">
                  </video> 
                </a>
              </div>
            </flickity>
          </Transition>
        </div>

        <div>
          <p class="inter-medium-alto-m">{{ imgs[index].title.rendered}}</p>
          <p class="inter-normal-alto-m">{{ imgs[index].acf.short_description }}</p>
        </div>

        <div class="portfolio_link">
          <div id="latest-post-buttons">
          <a :href="imgs[index].acf.getty__istock_link" target="_blank">
            <button-light-big style="display: inline-block" content="See more" />
          </a>
          <div v-for="categorie in displayCategories" :key="categorie.id">
            <secondaryButtonDark class="categorie" v-if="categorie.acf.getty_link == ''" v-show="imgs[index].acf.category.includes(categorie.id)" @click="toCategorie(categorie.id, categorie.name)" :content="categorie.name" />
            <a v-else :href="categorie.acf.getty_link" target="_blank">
              <secondaryButtonDark class="categorie" v-show="imgs[index].acf.category.includes(categorie.id)" :content="categorie.name" />
            </a>
          </div>
          </div>
          
        </div>
      </div>
    </div>
  </Transition>
</template>
  
<script>
import axios from 'axios'
import flickity from 'vue-flickity'

import buttonLightBig from '../buttons/buttonLightBig.vue';
import secondaryButtonDark from '../buttons/secondaryButtonDark.vue';

export default {
  name: "portfolioCard",
  data() {
    return {
      imgs: [],
      index: 0,
      selector: ".first",
      flickityOptions: {
        initialIndex: 0,
        prevNextButtons: true,
        pageDots: false,
        contain: true,
      },
      displayCategories: []
    }
  },
  components: {
    flickity,
   
    buttonLightBig,
    secondaryButtonDark
  },
  created() {
    this.getData();

  },
  methods: {
    async getData() {
      try {
        let response = await axios.get(this.$baseUrl + "/wp/wp-json/wp/v2/showcase");
        let differentCategories = []

        this.imgs = await response.data;
        for(var i = 0; i < 6; i++) {
          differentCategories = differentCategories.concat(this.imgs[i].acf.category);
        }
        differentCategories = [... new Set(differentCategories)];

        let categories = await axios.get(this.$baseUrl + "/wp/wp-json/wp/v2/categories?include=" + differentCategories.toString());
        this.displayCategories = await categories.data;

        this.$emit('isloaded')
      } catch (error) {
        console.log(error);
      }
    },
    next() {
      if (this.index < 5) {
        this.index++
        this.setSelected(this.index)
      }
      this.$refs.flickity.next();
    },

    previous() {
      if (this.index != 0) {
        this.index--
        this.setSelected(this.index)
      }
      this.$refs.flickity.previous();
    },
    setSelected(i) {
      // if (document.querySelector(".is-selected") != null) {
      //   document.querySelector(".is-selected").classList.remove("is-selected");
      // }
      this.index = i;
      this.selector = ".cell" + i;
      // document.querySelector(this.selector).classList.add("is-selected")
    },
    toCategorie(id, name) {
      this.$router.push({
          path: '/categorie',
          query: { 
            id: id,
            name: name
          }
      })
    },
    onInit(){ this.$refs.flickity.on('change', (index) => { this.setSelected(index) }) }
  }
};
</script>
  
<style>
#latest-post-buttons {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  height: fit-content;
}

#pc {
  transition: 300ms;
}

.carousel-cell {
  width: 43vw;
  height: 36vw;
  margin-right: 20px;
  min-width: 330px;
  min-height: 250px;
  max-width: 650px;
  max-height: 500px;
}

.card {
  background-color: rgb(15, 15, 15);
  position: relative;
  display: flex;
  flex-direction: column;
  height: fit-content;
  text-align: left;
  padding-top: 60px;
  padding-bottom: 60px;

}

.latest_project {
  padding-bottom: 10px;
}

.img11 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.portfolio_link {
  width: 100%;
}

/* .carousel_buttons {
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  bottom: 90px;
} */

@media (max-width: 480px) {
  .carousel_buttons {
    display: none;
  }
}

.portfolio_button {
  margin: auto;
}

.sub_heading {
  align-self: left;
}

.heading {
  align-self: left;
  margin-bottom: 30px;
}


.carousel-cell::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 10px;
  bottom: 0;
  left: 0;
  background-color: #931e189a;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}


/* .is-selected::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(1);
  height: 10px;
  bottom: 0;
  left: 0;
  background-color: #931e189a;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
} */

.wrapper-portfolio {
  background-color: rgb(15, 15, 15);
}

.portfolio_link {
  margin-top: 60px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
</style>