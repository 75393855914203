<template>
    <div id="about-wrapper">
    <Transition appear name="fade">
        <div id="container-about" class="container" v-if="aboutText[0]">
            <div class="about">
                <p class="inter-semi-bold-alto-l">{{aboutText[0].title.rendered}}</p>
                <br>
                <p class="inter-medium-alto-s" v-html="aboutText[0].acf.about.replaceAll('\r\n', '<br>')"></p>
            </div>
        </div>
    </Transition>
    <Transition appear name="fade">
        <footerShort v-if="loaded"/>
    </Transition>
    </div>
</template>
    
<script>
import footerShort from '@/components/footerShort.vue';
import axios from 'axios';

export default {
    name: "aboutPage",
    data() {
        return {
            aboutText: "",
            loaded: false
        }
    },
    components: {
        footerShort
    },
    created() {
        this.getData()
    },
    methods: {
        async getData() {
            try {
                let response = await axios.get(this.$baseUrl + "/wp/wp-json/wp/v2/about");
                this.aboutText = await response.data;
                this.loaded = true;
            } catch (error) {
                console.log(error);
            }
        }
    },
}
</script>
    
<style>
#container-about {
    padding-top: 120px;
}

.about {
    width: 50vw;
    min-width: 330px;
    margin-left: 0;
    margin-right: auto;
    padding-bottom: 120px;
}
#about-wrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
</style>