<template>
    <div class="nav-wrapper footer-dark">
        <div class="container">
            <p class="inter-medium-alto-m">SimonKr Stock Content Production</p>
        </div>
        <div class="nav-bar container">
            <div>
                <a href="https://www.facebook.com/simonkrvideoproduction/" target="_blank">
                    <img class="icon" :src="this.$baseUrl + '/icons/icon-facebook.png'">
                </a>
                <a href="https://twitter.com/simonkrzic/" target="_blank">
                    <img class="icon" :src="this.$baseUrl + '/icons/icon-twitter.png'">
                </a>
                <a href="https://vimeo.com/simonkr" target="_blank">
                    <img class="icon" :src="this.$baseUrl + '/icons/icon-vimeo.png'">
                </a>
                <a href="https://www.youtube.com/user/simonkrzic" target="_blank">
                    <img class="icon" :src="this.$baseUrl + '/icons/icon-youtube.png'">
                </a>
                <a href="https://www.instagram.com/simonkrvp/" target="_blank">
                    <img class="icon" :src="this.$baseUrl + '/icons/icon-instagram.png'">
                </a>
                <a href="https://www.tiktok.com/@simonkrvideoproduction?lang=en" target="_blank">
                    <img class="icon" :src="this.$baseUrl + '/icons/icon-tiktok.png'">
                </a>
            </div>
            <div>
                <a href="https://www.gettyimages.com/search/photographer?photographer=simonkr" target="_blank">
                    <img class="icon-medium" :src="this.$baseUrl + '/icons/getty-logo.png'">
                </a>
                <a href="https://www.istockphoto.com/portfolio/simonkr?mediatype=photography" target="_blank">
                    <img class="icon-small" :src="this.$baseUrl + '/icons/istock-logo.png'">
                </a>
            </div>
        </div>
    </div>
</template>
    
<script>
export default {
    name: "footerElementDark"
};
</script>
    
<style>
.footer-dark {
    position: relative;
    background-color: rgb(15, 15, 15);
    width: 100%;
    padding-top: 15px;
    z-index: 500;
}
</style>