<template>
    <div class="contact-footer">
        <div class="container nav-bar">
            <div>
                <a href="https://www.facebook.com/simonkrvideoproduction/" target="_blank">
                    <img class="icon" :src="this.$baseUrl + '/icons-dark/icon-facebook-dark.png'">
                </a>
                <a href="https://twitter.com/simonkrzic/" target="_blank">
                    <img class="icon" :src="this.$baseUrl + '/icons-dark/icon-twitter-dark.png'">
                </a>
                <a href="https://vimeo.com/simonkr" target="_blank">
                    <img class="icon" :src="this.$baseUrl + '/icons-dark/icon-vimeo-dark.png'">
                </a>
                <a href="https://www.youtube.com/user/simonkrzic" target="_blank">
                    <img class="icon" :src="this.$baseUrl + '/icons-dark/icon-youtube-dark.png'">
                </a>
                <a href="https://www.instagram.com/simonkrvp/" target="_blank">
                    <img class="icon" :src="this.$baseUrl + '/icons-dark/icon-instagram-dark.png'">
                </a>
                <a href="https://www.tiktok.com/@simonkrvideoproduction?lang=en" target="_blank">
                    <img class="icon" :src="this.$baseUrl + '/icons-dark/icon-tiktok-dark.png'">
                </a>
            </div>
            <div>
                <a href="https://www.gettyimages.com/search/photographer?photographer=simonkr" target="_blank">
                    <img class="icon-medium" :src="this.$baseUrl + '/icons-dark/getty-logo-dark.png'">
                </a>
                <a href="https://www.istockphoto.com/portfolio/simonkr?mediatype=photography" target="_blank">
                    <img class="icon-small" :src="this.$baseUrl + '/icons-dark/istock-logo-dark.png'">
                </a>
            </div>
        </div>
        <div id="img_contain">
            <img class="clients-img" :src="this.$baseUrl + '/clients-2.png'">
        </div>
    </div>
</template>
    
<script>
export default {
    name: "footerShortElement",
    methods: {
    }
};
</script>
    
<style>
.contact-footer {
    position: relative;
    background-color: white;
    width: 100%;
}

.clients-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    min-width: 1000px;
    overflow: hidden;
}

#img_contain {
    position: -webkit-sticky;
    bottom: 0;
    overflow: hidden;
    background-color: black;
}
</style>